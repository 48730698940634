import { XF } from "./XF";

XF.Event.extend("menu", {
  click(e: Event) {
    this.$target.addClassTransitioned("is-active");
    e.preventDefault();
    Object.getPrototypeOf(Object.getPrototypeOf(this)).click.call(this, e);

    // iOS has a known flaw of not repositioning elements when keyboard shown
    if (XF.isIOS()) {
      setTimeout(() => {
        const $buttonBottom = this.$target[0].getBoundingClientRect().bottom;
        const $menuBottom = this.$menu[0].getBoundingClientRect().bottom;

        const $scrollTargetEl =
          $menuBottom >= $buttonBottom ? this.$menu[0] : this.$target[0];

        $scrollTargetEl?.scrollIntoView({
          behavior: "smooth",
          block: "nearest",
          inline: "start",
        });
      }, 100);
    }
  },
  getHorizontalPosition(viewport, menuCss) {
    let menuIsRight = false;
    let deltaLeft = 0;

    if (this.menuWidth > viewport.width) {
      // align menu to left viewport edge if menu is wider than viewport
      deltaLeft = this.menuRef.left - viewport.left;
    } else if (
      this.menuRef.left + this.menuRef.width / 2 >
      viewport.width * this.options.directionThreshold
    ) {
      // align menu to right of this.menuRef if this.menuRef center is viewportwidth/directionThreshold of the page width
      deltaLeft = 0 - this.menuWidth + this.menuRef.width;
      menuIsRight = true;
    } else if (this.menuRef.width > this.menuWidth) {
      // align menu with middle of the ref
      deltaLeft = Math.floor((this.menuRef.width - this.menuWidth) / 2);
    }

    // corrections to constrain to viewport, as much as possible, with 5px to spare
    deltaLeft = Math.min(
      deltaLeft,
      viewport.right - this.menuWidth - this.menuRef.left - 5
    );
    deltaLeft = Math.max(deltaLeft, viewport.left - this.menuRef.left + 5);

    // Line up the menu with the right side of the button that triggered it
    const buttonOffset =
      this.menuRef.width - (this.$target.offset().left - this.menuRef.left);

    // final calculation for menu left position
    menuCss.left =
      this.menuRef.left + deltaLeft - buttonOffset + this.$target.outerWidth();

    this.$target.data("menu-h", [
      this.menuRef.left,
      this.menuRef.width,
      deltaLeft,
    ]);

    this.$menu
      .toggleClass("menu--left", !menuIsRight)
      .toggleClass("menu--right", menuIsRight);

    // don't allow the arrow to be moved outside of the menu
    const arrowOffset = Math.min(
      this.arrowRef.left -
        this.menuRef.left +
        this.arrowRef.width / 2 -
        deltaLeft,
      this.menuWidth - 20
    );

    this.$menu.find(".menu-arrow").css({
      top: "",
      left: arrowOffset,
    });

    return menuCss;
  },
  init() {
    if (this.options.menu) {
      this.$menu = XF.findRelativeIf(this.options.menu, this.$target);
    }
    if (!this.$menu || !this.$menu.length) {
      this.$menu = this.$target.nextAll("[data-menu]").first();
    }

    if (!this.$menu.length) {
      console.error("No menu found for %o", this.$target[0]);
      return;
    }

    this.$menuPosRef = this.$target;
    this.$arrowPosRef = this.$target;

    if (this.options.menuPosRef) {
      const $menuPosRef = XF.findRelativeIf(
        this.options.menuPosRef,
        this.$target
      );
      if ($menuPosRef.length) {
        this.$menuPosRef = $menuPosRef;

        if (this.options.arrowPosRef) {
          // only check for arrowPosRef if we have a menuPosRef,
          // and only allow it if it's a child of menuPosRef (or the same as menuPosRef)

          const $arrowPosRef = XF.findRelativeIf(
            this.options.arrowPosRef,
            this.$target
          );
          if ($arrowPosRef.closest($menuPosRef).length) {
            this.$arrowPosRef = $arrowPosRef;
          }
        }
      }
    }

    this.$target.attr("aria-controls", this.$menu.xfUniqueId());

    if (!this.$menu.find(".menu-arrow").length) {
      this.$menu.prepend('<span class="menu-arrow" />');
    }

    this.$menu
      .data("menu-trigger", this)
      .on("click", "[data-menu-closer]", () => {
        this.close(true);
      })
      .on({
        "menu:open": () => {
          this.open(XF.Feature.has("touchevents"));
        },
        "menu:close": () => {
          this.close();
        },
        "menu:reposition": () => {
          if (this.isOpen()) {
            this.reposition();
          }
        },
        keydown: XF.proxy(this, "keyboardEvent"),
      });

    if (XF.isIOS() && this.$target.hasFixableParent()) {
      // iOS has major issues with inputs within fixed elements
      let blurTimeout;
      let focusTimer;

      this.$menu.on(
        {
          touchstart(e) {
            clearTimeout(blurTimeout);
            clearTimeout(focusTimer);

            this.enableIOSInputFix($(e.currentTarget));

            // if this doesn't lead to a focus event, we need to disable it
            focusTimer = setTimeout(() => {
              this.resetIOSInputFix();
            }, 500);
          },
          focus(e) {
            clearTimeout(blurTimeout);
            clearTimeout(focusTimer);
            this.enableIOSInputFixFocus($(e.currentTarget));
          },
          blur() {
            clearTimeout(blurTimeout);
            blurTimeout = setTimeout(() => {
              if (this.isOpen()) {
                this.resetIOSInputFix();
              }
            }, 200);
          },
        },
        XF.getKeyboardInputs()
      );
    }

    const $tooltip = this.$menu.closest(".tooltip");
    if ($tooltip.length) {
      $tooltip.on("tooltip:hidden", XF.proxy(this, "close"));
    }

    const builder = this.$menu.data("menu-builder");
    if (builder) {
      if (XF.MenuBuilder[builder]) {
        XF.MenuBuilder[builder](this.$menu, this.$target, this);
      } else {
        console.error(`No menu builder ${builder} found`);
      }
    }
  },
  close(menuCloserClicked) {
    const { $menu } = this;

    // If we didn't click on another menu tab, keep the active underscore on the dropdown
    if (!menuCloserClicked) {
      this.$target.removeClassTransitioned("is-active");
    }

    if (!this.isOpen()) {
      return;
    }

    this.$target
      .attr("aria-expanded", "false")
      .removeClassTransitioned(this.options.targetOpenClass);
    $menu
      .attr("aria-hidden", "true")
      .removeClass(this.options.completeClass)
      .removeClassTransitioned(this.options.openClass);
    this.$menuPosRef.removeClassTransitioned(this.options.targetOpenClass);

    $(window).offPassive("scroll", this.scrollFunction);

    XF.MenuWatcher.onClose($menu);

    this.$target.trigger("menu:closed", [$menu]);
    $menu.trigger("menu:closed");

    this.resetIOSInputFix();
  },
});

// Override default XF.PageJump's go func
XF.Element.extend("page-jump", {
  go() {
    let page = parseInt(this.$input.val(), 10);
    if (page < 1) {
      page = 1;
    }

    const baseUrl = this.options.pageUrl;
    const { sentinel } = this.options;
    let url = baseUrl.replace(sentinel, page);

    // eslint-disable-next-line eqeqeq
    if (url == baseUrl) {
      url = baseUrl.replace(encodeURIComponent(sentinel), page);
    }

    // Override Point - Add in current anchor to url if its not there
    const currentAnchor = $(window.location).attr("hash");
    if (currentAnchor && !url.includes("#")) {
      url += currentAnchor;
    }

    XF.redirect(url);
  },
});

XF.Element.extend("tabs", {
  getSelectorFromHash() {
    let selector = "";
    if (window.location.hash.length > 1) {
      const hash = window.location.hash.replace(/[^a-zA-Z0-9_-]/g, "");
      if (hash && hash.length) {
        /* fix issue with #profile-post-[id] or #profile-post-comment-[id] */
        if (hash.match(/^profile-post(-comment)?-\d+$/gi)) {
          return "#profile-posts";
        }

        selector = `#${hash}`;
      }
    }
    return selector;
  },

  onPopState(e) {
    const popStateEvent = e.originalEvent;
    const { state } = popStateEvent;

    if (state && state.id) {
      this.activateTarget(`#${state.id}`, false);
    } else if (state && state.offset) {
      this.activateTab(state.offset);
    }
  },

  activateTab(offset, menuCloserClicked) {
    const $tab = this.$tabs.eq(offset);
    const $pane = this.$panes.eq(offset);
    const { activeClass } = this.options;

    if (!$tab.length || !$pane.length) {
      console.error(`Selected invalid tab ${offset}`);
      return;
    }

    // deactivate active other tab
    this.$tabs
      .filter(`.${activeClass}`)
      .removeClass(activeClass)
      .attr("aria-selected", "false")
      .trigger("tab:hidden");
    this.$panes
      .filter(`.${activeClass}`)
      .removeClass(activeClass)
      .attr("aria-expanded", "false")
      .trigger("tab:hidden");

    // If the click came from a tab rather than a dropdown menu, clear the
    // underscore on dropdowns currently selected
    if (!menuCloserClicked) {
      this.$target[0].querySelectorAll(".dropdown-tab").forEach((element) => {
        element.classList.remove(activeClass);
      });
    }

    // activate tab
    $tab
      .addClass(activeClass)
      .attr("aria-selected", "true")
      .trigger("tab:shown");
    $pane
      .addClass(activeClass)
      .attr("aria-expanded", "true")
      .trigger("tab:shown");

    XF.layoutChange();

    if ($pane.data("href")) {
      if ($pane.data("tab-loading")) {
        return;
      }
      $pane.data("tab-loading", true);

      XF.ajax("get", $pane.data("href"), {}, (data) => {
        $pane.data("href", false);
        if (data.html) {
          const loadTarget = $pane.data("load-target");
          if (loadTarget) {
            XF.setupHtmlInsert(data.html, $pane.find(loadTarget));
          } else {
            XF.setupHtmlInsert(data.html, $pane);
          }
        }
      }).always(() => {
        $pane.data("tab-loading", false);
      });
    }
  },

  tabClick(e) {
    const click = e.currentTarget;
    const offset = this.$tabs.index(click);
    const $tab = this.$tabs.eq(offset);
    const event = $.Event("tab:click");
    const dataMenuCloserClicked = e.target.hasAttribute("data-menu-closer");

    if (offset === -1) {
      console.error("Did not find clicked element (%o) in tabs", click);
      return;
    }

    $tab.trigger(event, this);
    if (event.isDefaultPrevented()) {
      return;
    }

    if (this.options.preventDefault) {
      e.preventDefault();
    }

    if (this.options.state) {
      let href = window.location.href.split("#")[0];
      let state = {};

      if ($tab.attr("id")) {
        href = `${href}#${$tab.attr("id")}`;
        state = {
          id: $tab.attr("id"),
        };
      } else {
        state = {
          offset,
        };
      }

      switch (this.options.state) {
        case "replace":
          window.history.replaceState(state, "", href);
          break;

        case "push":
          window.history.pushState(state, "", href);
          break;

        default:
          break;
      }
    }

    this.activateTab(offset, dataMenuCloserClicked);
  },
});

XF.Event.extend("overlay", {
  show(this: typeof XF.OverlayClick, e: Event) {
    const parentClass = Object.getPrototypeOf(Object.getPrototypeOf(this));
    parentClass.show.call(this, e);
    $(document).trigger("overlay:shown", this.loadUrl);
  },
});
